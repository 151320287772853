import darkColors from 'common/theme/dark/darkColors';

export enum Theme {
  LIGHT = 'light',
  DARK = 'dark',
}

export type Colors = {
  transparent: string;
  primaryTextColor: string;
  warningTextColor: string;
  spinnerColor: string;
  black: string;
  white: string;
  headingColor: string;
  selectedColor: string;
  sidebarTextColor: string;
  sidebarTextSelectedColor: string;
  sidebarItemSelectedColor: string;
  textColor: string;
  subTextColor: string;
  labelColor: string;
  inactiveField: string;
  inactiveButton: string;
  inactiveIcon: string;
  primary: string;
  primaryDisabled: string;
  primaryHover: string;
  secondary: string;
  secondaryHover: string;
  yellow: string;
  yellowHover: string;
  innerBoxBgColor: string;
  // Wallet
  walletButtonBgColor: string;
  // General
  primaryButtonBgColor: string;
  primaryButtonDisabledBgColor: string;
  primaryButtonColor: string;
  secondaryButtonBgColor: string;
  hrBgColor: string;
  footerBgColor: string;
  primaryBoxShadow: string;
  secondaryBoxShadow: string;
  sidebarBackgroundColor: string;
  contentBgColor: string;
  contentInnerBgColor: string;
  contentInnerInnerBgColor: string;
  annoncementBgColor: string;
  backgroundColor: string;
  borderColor: string;
  hamburgerIcon: string;
  // Tabs
  activeTabColor: string;
  inactiveTabColor: string;
  btnViewAllColor: string;
  // Treasury
  treasuryPositionBorder: string;
  treasuryPositionBgColor: string;
  treasuryPositionNameColor: string;
  treasuryTableSubTextTwoColor: string;
  // Farm & Vault
  farmDashesColor: string;
  noRewardsButtonColor: string;
  vaultBannerBorderColor: string;
  // General Table
  tableBorderBottom: string;
  // General Input
  inputBorder: string;
  inputBorderColor: string;
  inputLabelColor: string;
  inputBgColor: string;
  inputPlaceholderColor: string;
  // Ichi Balance
  ichiBalanceButtonBgColor: string;
  ichiBalanceBgColor: string;
  ichiBalanceHeaderBorderBottom: string;
  ichiBalanceHeaderTextColor: string;
  ichiBalancePrimaryTextColor: string;
  // Modal
  modalBgColor: string;
  // select stablecoin
  selectStablecoinBgColor: string;
  // Wallet
  walletPopperHeaderBorderBottom: string;
  walletPopperHeaderTextColor: string;
  walletPopperBgColor: string;
  walletPopperTextColor: string;
  navButtonBgColor: string;
};

export type ColorsKey = keyof typeof darkColors;

export type IchiTheme = {
  name: Theme;
  // TODO: type this later, looks complicated
  mediaWidth: any;
  icons: {
    sidebar: string;
    theme: string;
    themeMobile: string;
    preferences: {
      active: string;
      inactive: string;
      inactiveMobile: string;
    };
    help: string;
    ichiWhite: string;
    ichiMobile: string;
    aquaberaIconLogo: string;
    xIchiOwned: string;
    xIchiIchiRatio: string;
    ichiApy: string;
    close: string;
    newWindow: string;
    newWindowWhite: string;
    lp: string;
    eth: string;
    ethWhite: string;
    maticWhite: string;
    wbtc: string;
    renFIL: string;
    fuse: string;
    polygonBadge: string;
  };
  breakpoints: string[];
  space: number[];
  fontSizes: number[];
  fontWeights: number[];
  lineHeights: {
    solid: number;
    title: number;
    copy: number;
  };
  letterSpacings: {
    normal: string;
    tracked: string;
    tight: string;
    mega: string;
  };
  borders: any[];
  radius: any[];
  widths: number[];
  heights: number[];
  maxWidths: number[];
  colors: Colors;

  colorStyles: {
    primaryText: {
      color: ColorsKey;
    };
    primary: {
      color: ColorsKey;
      borderColor: ColorsKey;
      backgroundColor: ColorsKey;
      '&:hover': {
        color: ColorsKey;
        backgroundColor: ColorsKey;
      };
    };
    secondary: {
      color: ColorsKey;
      borderColor: ColorsKey;
      backgroundColor: ColorsKey;
      '&:hover': {
        color: ColorsKey;
        borderColor: ColorsKey;
        backgroundColor: ColorsKey;
      };
    };
    warning: {
      color: ColorsKey;
      borderColor: ColorsKey;
      '&:hover': {
        color: ColorsKey;
        borderColor: ColorsKey;
      };
    };
    error: {
      color: ColorsKey;
      borderColor: ColorsKey;
      '&:hover': {
        color: ColorsKey;
        borderColor: ColorsKey;
      };
    };
    primaryWithBg: {
      color: ColorsKey;
      backgroundColor: ColorsKey;
      borderColor: ColorsKey;
      border: '1px solid';
      '&:hover': {
        color: ColorsKey;
        backgroundColor: ColorsKey;
        borderColor: ColorsKey;
        boxShadow: ColorsKey;
      };
    };
    secondaryWithBg: {
      color: ColorsKey;
      backgroundColor: ColorsKey;
      borderColor: ColorsKey;
      '&:hover': {
        color: ColorsKey;
        backgroundColor: ColorsKey;
        borderColor: ColorsKey;
        boxShadow: ColorsKey;
      };
    };
    warningWithBg: {
      color: ColorsKey;
      backgroundColor: ColorsKey;
      borderColor: ColorsKey;
      '&:hover': {
        backgroundColor: ColorsKey;
        borderColor: ColorsKey;
      };
    };
    errorWithBg: {
      color: ColorsKey;
      backgroundColor: ColorsKey;
      borderColor: ColorsKey;
      '&:hover': {
        backgroundColor: ColorsKey;
        borderColor: ColorsKey;
      };
    };
    transparentBg: {
      backgroundColor: ColorsKey;
      '&:hover': {
        backgroundColor: ColorsKey;
      };
    };
  };
  buttonStyles: {
    primaryButton: {
      border: string;
      borderRadius: string;
      color: ColorsKey;
      backgroundColor: ColorsKey;
      padding: string;
      height: string;
      '&:hover': {
        cursor: string;
        textDecoration: string;
      };
    };
    textButton: {
      border: number;
      color: ColorsKey;
      padding: number;
      height: string;
      backgroundColor: ColorsKey;
    };
    outlined: {
      borderWidth: string;
      borderStyle: string;
      backgroundColor: ColorsKey;
    };
    fab: {
      border: string;
      width: string;
      height: string;
      padding: number;
      borderRadius: string;
      justifyContent: string;
      'span.btn-icon': {
        paddingLeft: 0;
      };
    };
    extendedFab: {
      border: string;
      minWidth: string;
      height: string;
      borderRadius: string;
      justifyContent: string;
    };
  };
};
